var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box",
      class: { hover: _vm.isHover, select: _vm.isSelect },
      style: _vm.style,
      on: {
        mouseenter: function($event) {
          $event.preventDefault()
          return _vm.mouseenter($event)
        },
        mouseleave: _vm.mouseleave,
        mousedown: function($event) {
          $event.preventDefault()
          return _vm.mousedown($event)
        },
        mouseup: function($event) {
          $event.preventDefault()
          return _vm.mouseup($event)
        }
      }
    },
    [
      _vm._t("default"),
      _vm.drag
        ? _c("div", { staticClass: "drag", on: { mousedown: _vm.dragstart } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }