import "core-js/modules/es.array.concat";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Throttle, Raf } from '@/plugins/decorator/index';
export default {
  props: {
    info: {
      required: true },

    idx: {
      required: true },

    ratioX: {
      "default": 1 },

    ratioY: {
      "default": 1 },

    // 是否可移动
    move: {
      "default": true },

    // 是否可拖动大小
    drag: {
      "default": true },

    // 是否可以 hover
    hover: {
      "default": true },

    // 是否可以选择 select
    select: {
      "default": true },

    // 已选中
    isSelect: {
      "default": false },

    // 已 hover
    isHover: {
      "default": false } },


  data: function data() {
    return {
      startX: 0,
      startY: 0
      // moveable: false
      // timer: null,
      // delay: 200
    };
  },
  computed: {
    style: function style() {var _this$info =
      this.info,x = _this$info.x,y = _this$info.y,w = _this$info.w,h = _this$info.h,style = _this$info.style;

      var color =
      this.info.status === 1 ? '#e44949' : this.isSelect ? '#009274' : '';

      return "\n        left: ".concat(
      x * this.ratioX, "px;\n        top: ").concat(
      y * this.ratioY, "px;\n        width: ").concat(
      w * this.ratioX, "px;\n        height: ").concat(
      h * this.ratioY, "px;\n        background-color: ").concat(
      color, "\n      ");

    } },

  methods: {
    init: function init() {},
    mousedown: function mousedown(e) {
      if (this.select) {
        this.$emit('select', this.idx);
      }

      if (this.move) {
        this.$emit('move', _objectSpread(_objectSpread({},
        this.info), {}, {
          clientX: e.clientX,
          clientY: e.clientY,
          idx: this.idx }));

      }
    },
    mouseenter: function mouseenter() {
      if (this.hover) {
        this.$emit('hover', this.idx);
      }
    },
    mouseleave: function mouseleave() {
      if (this.hover) {
        this.$emit('hover');
      }
    },
    mouseup: function mouseup() {},
    dragstart: function dragstart(e) {
      if (this.drag) {
        this.$emit('drag', true);
      }
    } } };